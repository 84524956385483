import React, { useEffect } from "react";
import Modal from 'react-modal';

const TermsModal = ({}) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  useEffect(() => {
    const acceptedTerms = localStorage.getItem('TERMS_ACCEPTED');
    if (acceptedTerms !== 'true') {
      setIsOpen(true);
    }
  }, []);

  return (
    <Modal
        isOpen={modalIsOpen}
        style={{
          content: {
            maxWidth: '500px',
            top: '50%',
            left: '50%',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
          }
        }}
      >
        
        <div style={{ fontSize: '13px' }}>
          <h2>
          Условия использования и политика конфиденциальности для онлайн-опроса об искусственном интеллекте
          </h2>

          <h3>Введение</h3>
          Добро пожаловать в наш онлайн-опрос, посвященный искусственному интеллекту в повседневной жизни. Этот опрос проводится независимым исследователем с целью изучения популярных приложений ИИ и направления будущих исследований и разработок в отрасли науки и технологий. Участие в этом опросе является добровольным.

          <h3>Сбор и использование данных</h3>

          Что мы собираем: Мы собираем ответы на вопросы опроса. Предоставление имени, адреса электронной почты и номера телефона является добровольным и необходимо только в том случае, если вы хотите принять участие в последующей виртуальной фокус-группе. Технические лог-данные, включая IP-адреса и строки агента пользователя, могут собираться нашим веб-сервером.
          Цель: Данные собираются для информирования исследований общественного мнения относительно приложений ИИ.
          Хранение данных: Данные будут храниться до завершения исследовательского проекта, предположительно не более 6 месяцев.
          Использование данных: Данные будут использоваться исключительно в исследовательских целях и не будут использоваться для коммерческих или маркетинговых целей.

          <h3>Согласие</h3>
          Участвуя в этом опросе, вы даете согласие на сбор и использование ваших данных, как описано в этом документе. Вы можете отозвать свое согласие в любое время, связавшись с нами.

          <h3>Меры безопасности</h3>
          Данные будут храниться на защищенной облачной инфраструктуре с такими мерами защиты, как обнаружение вторжений, предотвращение DDoS-атак, доступ по учетным данным, двухфакторная аутентификация и шифрование военного класса.

          <h3>Распространение и передача данных</h3>

          Третьи стороны: Ваши данные не будут передаваться третьим сторонам, за исключением случаев, требуемых правительственными или правоохранительными органами.
          Международная передача: Данные будут доступны из Республики Грузия. Только анонимизированные результаты будут опубликованы публично.
          Ваши права
          Участники имеют право получить доступ к своим данным и отозвать свое участие в опросе в любой момент. Для любых запросов или вопросов свяжитесь с ai_surveys@proton.me  или отправьте SMS на номер +79228190809.

          <h3>Язык</h3>
          Данный документ предоставлен на русском языке.

          <h3>Контактная информация</h3>
          По любым вопросам или проблемам, пожалуйста, свяжитесь с нами по адресу ai_surveys@proton.me  или отправьте SMS на номер +79228190809.

          <h3>Обновления политики</h3>
          Изменения политики не предвидятся. Однако любые изменения будут сообщены участникам, которые предоставили свои адреса электронной почты, и уведомления будут размещены на веб-сайте опроса.

          <h3>Подтверждение</h3>
          Продолжая участвовать в этом опросе, вы подтверждаете, что прочитали, поняли и согласны с условиями и положениями, изложенными в этом документе.

          
          <button className="button" style={{ marginTop: '15px' }} onClick={() => {
            setIsOpen(false);
            localStorage.setItem('TERMS_ACCEPTED', 'true');
          }}>Я подтверждаю</button>
        </div>
      </Modal>
  );
};

export default TermsModal;
