import axios, { AxiosRequestConfig } from "axios";
import { Answers } from "../components/polling";

const API_URL = process.env.REACT_APP_API_URL;

const API = axios.create({
  baseURL: API_URL,
});

interface RetryConfig extends AxiosRequestConfig {
  retry: number;
  retryDelay: number;
}

const globalConfig: RetryConfig = {
  retry: 3,
  retryDelay: 1000,
};

API.interceptors.response.use(
    (response) => response,
    (err) => {
      const { config } = err;

      if (!config || !config.retry) {
        return Promise.reject(err);
      }
      config.retry -= 1
      const delayRetryRequest = new Promise<void>((resolve) => {
        setTimeout(() => {
          console.log("retry the request", config.url);
          resolve();
        }, config.retryDelay || 1000)
      })
      return delayRetryRequest.then(() => API(config));
    }
);

export interface WebinarDataResponse {
  data: {
    id: string;
    attributes: {
      avatar_url: string;
      campaign_id: number;
      cta_text: string;
      description: string;
      host_name: string;
      host_info: string;
      map_url: string;
      // survey_videos_webinars: { survey_video_id: number; index: number }[];
      title: string;
      number_of_participants: number;
      skip_intro: boolean;
    };
  };
  included: {
    attributes: {
      survey_video_id: number;
      webinar_id: number;
    };
    type: string;
  }[];
}

export interface WebinarData {
  id: string;
  attributes: {
    avatar_url: string;
    campaign_id: number;
    cta_text: string;
    description: string;
    host_name: string;
    host_info: string;
    map_url: string;
    // survey_videos_webinars: { survey_video_id: number; index: number }[];
    title: string;
    number_of_participants: number;
    skip_intro: boolean;
  };
  included: {
    attributes: {
      survey_video_id: number;
      webinar_id: number;
    };
    type: string;
  }[];
}

export interface VideoData {
  id: string;
  attributes: {
    name: string;
    video_url: string;
    chat_messages: { name: string; content: string; show_at: string }[];
    survey_forms: { survey_form_id: number; video_stop_time: string }[];
  };
}

export interface SurveyData {
  id: string;
  attributes: {
    name: string;
    questions: {
      id: number;
      question: string;
      answers: { id: number; answer: string }[];
    }[];
  };
}

export interface CampaignAnswer {
  id: number;
  content: string;
  selected?: boolean;
}

export enum CampaignQuestionKinds {
  RADIO = "radio",
  INPUT = "input",
}

export interface CampaignQuestion {
  id: number;
  content: string;
  header?: string | null;
  kind: CampaignQuestionKinds;
  answers?: CampaignAnswer[];
}

export interface CampaignResources {
  id: string;
  attributes: {
    inline_questions_with_answers: CampaignQuestion[];
    welcoming_description: string | null;
    welcoming_image_desktop_url: string | null;
    welcoming_image_mobile_url: string | null;
    welcoming_screen: boolean;
    welcoming_title: string | null;
    welcoming_action_text: string | null;
    pixel_id: string | null;
    after_survey_redirection_link: string | null;
    after_survey_call_to_action: string | null;
    after_survey_caption: string | null;
    thank_you_message: string | null;
  };
}

export interface CampaignResourcesScoreThreshold {
  id: string;
  type: string;
  attributes: {
    message_content: string | null;
    threshold_value: string | null;
  };
}

export const getWebinar = async (
  webinarId: string
): Promise<WebinarDataResponse> => {
  try {
    const response = await API.get(`/campaign_resources/${webinarId}`);
    return response.data;
  } catch (e: any) {
    console.log(e);
    return e;
  }
};

export const getWebinarVideo = async (
  videoId: number
): Promise<{ data: VideoData }> => {
  try {
    const response = await API.get(`/survey_videos/${videoId}`);
    return response.data;
  } catch (e: any) {
    console.log(e);
    return e;
  }
};

export const getWebinarSurvey = async (
  formId: number
): Promise<{ data: SurveyData }> => {
  try {
    const response = await API.get(`/survey_forms/${formId}`);
    return response.data;
  } catch (e: any) {
    console.log(e);
    return e;
  }
};

export const requestAddVote = async (
  body: any
): Promise<{ data: SurveyData }> => {
  try {
    const response = await API.post(`/scout_campaigns/filled_forms`, body, globalConfig);
    return response.data;
  } catch (e: any) {
    console.log(e);
    console.log(e.data);
    return e;
  }
};

export const requestAddMessage = async (
  body: any
): Promise<{ data: SurveyData }> => {
  try {
    const response = await API.post(`/viewer_chat_messages`, body);
    return response.data;
  } catch (e: any) {
    console.log(e);
    console.log(e.data);
    return e;
  }
};

export const getCampaignResources = async (
  campaignId: string,
  uuid: string | null
): Promise<{ data: CampaignResources, included: CampaignResourcesScoreThreshold[] }> => {
  try {
    const response = await API.get(`/campaign_resources/${campaignId}`, {
      params: {
        uuid,
      },
    });
    return response.data;
  } catch (e: any) {
    console.log(e);
    return e;
  }
};

export const internalUuidLog = async (
  pollingId: string,
  uuid: string | null,
  fingerprintHash: string | null
) => {
  const payload = { pollingId, uuid, fingerprintHash };

  try {
    const response = await fetch('/polling-uuid', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (response.ok) {
      console.log('Internal endpoint called successfully');
    } else {
      console.error('Failed to call internal endpoint:', response.status, response.statusText);
    }
  } catch (error) {
    console.error('Error calling internal endpoint:', error);
  };
};

// Deprecated - we should send single answers
export const sendFilledCampaign = async (
  uuid: string,
  campaign_id: string,
  answers: Answers
): Promise<any> => {
  try {
    const filledQuestions = Object.entries(answers).map(
      ([scout_question_id, _answers]) => ({
        scout_question_id,
        filled_campaign_questions_answers_attributes: _answers.map(
          (id: number) => ({
            scout_question_answer_id: id,
          })
        ),
      })
    );
    await API.post("scout_campaigns/filled_forms", {
      filled_campaign_form: {
        campaign_id,
        uuid,
        answered_at: new Date(),
        filled_campaign_questions_attributes: filledQuestions,
      },
    });
  } catch (e: any) {
    console.log(e);
    return e;
  }
};

export const sendCampaignAnswer = async (
  uuid: string,
  campaign_id: string,
  question_id: number,
  answer_id: number
): Promise<{ score: string }> => {
  try {
    const response: any = await API.post("scout_campaigns/filled_forms", {
      filled_campaign_form: {
        campaign_id,
        uuid,
        answered_at: new Date(),
        filled_campaign_questions_attributes: [
          {
            scout_question_id: question_id,
            filled_campaign_questions_answers_attributes: [
              {
                scout_question_answer_id: answer_id,
              },
            ],
          },
        ],
        entry_url: window?.location?.href
      },
    }, globalConfig);
    return response?.data?.data?.attributes
  } catch (e: any) {
    console.log(e);
    return e;
  }
};

export const sendCampaignOpenAnswer = async (
  uuid: string,
  campaign_id: string,
  question_id: number,
  answer_id: string
): Promise<any> => {
  try {
    await API.post("scout_campaigns/filled_forms", {
      filled_campaign_form: {
        campaign_id,
        uuid,
        answered_at: new Date(),
        filled_campaign_questions_attributes: [
          {
            scout_question_id: question_id,
            filled_campaign_questions_answers_attributes: [
              {
                scout_question_answer_content: answer_id,
              },
            ],
          },
        ],
        entry_url: window?.location?.href
      },
    }, globalConfig);
  } catch (e: any) {
    console.log(e);
    return e;
  }
};

export const internalAnswerLog = async (
  uuid: string,
  answer_id: number | string
): Promise<any> => {
  const payload = { answer_id: `${answer_id}`, uuid };

  try {
    const response = await fetch('/polling-answer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (response.ok) {
      console.log('Internal endpoint called successfully');
    } else {
      console.error('Failed to call internal endpoint:', response.status, response.statusText);
    }
  } catch (error) {
    console.error('Error calling internal endpoint:', error);
  };
};


export default API;
